<template>
  <div>
    <b-col md="12">
      <b-card
        :title="mode == 'add' ? 'เพิ่มบัญชี':'แก้ไขบัญชีธนาคาร'"
      >
        <b-row>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              เปิดใช้งาน
            </h5>
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              switch
              value="Y"
              unchecked-value="N"
              v-model="bankAccuntForm.active"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-75 mb-75">
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              ธนาคาร
            </h5>
            <v-select
              v-model="bankAccuntForm.bank_code"
              label="text"
              :options="bankListOptions"
              :reduce="bankItem => bankItem.value"
            >
              <template #option="{ text, value }">
                <img
                  :src="getBankImage(value)"
                  style="width: 24px;"
                >
                <span> {{ text }}</span>
              </template>
            </v-select>
          </b-col>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              ประเภทบัญชี
            </h5>
            <v-select
              v-model="bankAccuntForm.acc_type"
              label="text"
              :options="[{ text: 'ฝากเงิน', value: 'IN'}, { text: 'ถอนเงิน', value: 'OUT'}]"
              :reduce="bankItem => bankItem.value"
            />
          </b-col>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              ชื่อบัญชี
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="bankAccuntForm.acc_name"
              placeholder="ชื่อบัญชี"
            />
          </b-col>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              เลขที่บัญชี / เลขที่วอลเล็ท
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="bankAccuntForm.acc_no"
              placeholder="เลขที่บัญชี / เลขที่วอลเล็ท"
            />
          </b-col>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75">
              ชื่อย่อ
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="bankAccuntForm.bank_alias"
              placeholder="ชื่อย่อ SCB1/KBANK1/KTB1"
            />
          </b-col>
        </b-row>
        <template v-if="bankAccuntForm.bank_code == '004'">
          <h4 class="text-capitalize mb-75 mt-75">
            ข้อมูล บอทออโต้ อินเตอร์เนตแบงค์กิ้ง (K-Biz / SCB EASYNET / KTB NetBank)
          </h4>
          <b-row>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75">
                ชื่อผู้ใช้ (Username)
              </h5>
              <b-form-input
                id="mc-first-name"
                v-model="bankAccuntForm.bank_user"
                placeholder="ชื่อผู้ใช้"
              />
            </b-col>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75">
                รหัสผ่าน (Password)
              </h5>
              <b-form-input
                id="mc-first-name"
                v-model="bankAccuntForm.bank_pass"
                placeholder="รหัสผ่าน"
              />
            </b-col>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75">
                เบอร์โทร OTP
              </h5>
              <b-form-input
                id="mc-first-name"
                v-model="bankAccuntForm.otp_number"
                placeholder="เบอร์โทร OTP"
              />
            </b-col>
          </b-row>
        </template>
        <template v-if="bankAccuntForm.bank_code == '014'">
          <h4 class="text-capitalize mb-75 mt-75">
            ข้อมูล บอทออโต้ SCB Easy App
          </h4>
          <b-row>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75">
                DEVICE ID
              </h5>
              <b-form-input
                id="mc-first-name"
                v-model="bankAccuntForm.device_id"
                placeholder="รหัสอุปกรณ์ (Device ID)"
              />
            </b-col>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75">
                PIN
              </h5>
              <b-form-input
                id="mc-first-name"
                v-model="bankAccuntForm.bank_pin"
                placeholder="รหัสเข้าแอพ (PIN CODE)"
              />
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75 mt-75">
              บอท SMS
            </h5>
            <b-row class="">
              <b-col>
                <div style="display: flex; flex-direction: row;">
                  <b-form-checkbox
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="Y"
                    unchecked-value="N"
                    v-model="bankAccuntForm.bot_sms"
                  />
                  จับยอด
                  <div class="ml-75" style="display: flex; flex-direction: row;">
                    <b-form-checkbox
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      value="Y"
                      unchecked-value="N"
                      v-model="bankAccuntForm.auto_sms"
                    />
                    ออโต้
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <template v-if="bankAccuntForm.bank_code == '004'">
          <b-row>
            <b-col xs="6" md="3">
              <h5 class="text-capitalize mb-75 mt-75">
                บอท Internet Banking
              </h5>
              <b-row class="">
                <b-col>
                  <div style="display: flex; flex-direction: row;">
                    <b-form-checkbox
                      class="custom-control-success"
                      name="check-button"
                      switch
                      value="Y"
                      unchecked-value="N"
                      v-model="bankAccuntForm.bot_enet"
                    />
                    จับยอด
                    <div class="ml-75" style="display: flex; flex-direction: row;">
                      <b-form-checkbox
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        value="Y"
                        unchecked-value="N"
                        v-model="bankAccuntForm.auto_enet"
                      />
                      ออโต้
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <b-row v-if="bankAccuntForm.bank_code == '014'">
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75 mt-75">
              บอท APP
            </h5>
            <b-row class="">
              <b-col>
                <div style="display: flex; flex-direction: row;">
                  <b-form-checkbox
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="Y"
                    unchecked-value="N"
                    v-model="bankAccuntForm.bot_app"
                  />
                  จับยอด
                  <div class="ml-75" style="display: flex; flex-direction: row;">
                    <b-form-checkbox
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      value="Y"
                      unchecked-value="N"
                      v-model="bankAccuntForm.auto_app"
                    />
                    ออโต้
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="bankAccuntForm.acc_type == 'IN'">
          <b-col xs="6" md="3">
            <h5 class="text-capitalize mb-75 mt-75">
              แสดงหน้าเว็บ
            </h5>
            <b-row class="">
              <b-col>
                <div style="display: flex; flex-direction: row;">
                  <b-form-checkbox
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="Y"
                    unchecked-value="N"
                    v-model="bankAccuntForm.public"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr>
        <b-form @submit.prevent>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mb-1"
                @click="saveBankAccount"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BButton, BCardBody, BRow, BCol, BForm, BPagination, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    vSelect,
    // flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mode: 'update',
      bankAccuntForm: {
        active: 'N',
        bot_sms: 'Y',
        auto_sms: 'Y',
        bot_enet: 'Y',
        auto_enet: 'Y',
      },
      bankAccuntInfo: {
        active: 'N',
      },
      bankaccounts: [],
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]').filter(b => ['004', '014', '006', 'TMW'].indexOf(b.value) >= 0),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    if (this.$route.params.bankaccount === 'add-new-bank') {
      this.mode = 'add'
      this.bankAccuntInfo = {
        active: 'N',
      }
      this.bankAccuntForm = {
        active: 'N',
        bot_sms: 'Y',
        auto_sms: 'Y',
        bot_enet: 'Y',
        auto_enet: 'Y',
      }
    } else if (this.$route.params.bankaccount !== null && this.$route.params.bankaccount !== undefined && this.$route.params.bankaccount !== 'add-new-bank') {
      // this.userInput = this.$route.params.admin
      // this.fetchRecords()
      this.mode = 'update'
      this.fetchBankAccount(this.$route.params.bankaccount)
    }
  },
  methods: {
    clearSearch() {
      this.tableQuery = {
        page: 1,
        size: 100,
      }
      this.statementItems = []
    },
    async saveBankAccount() {
      console.log(this.bankAccuntForm)
      if (this.mode === 'update') {
        console.log(this.mode, this.bankAccuntForm, this.bankAccuntInfo)
        const changeMade = {}
        const allowChanges = ['active', 'bank_code', 'bank_alias', 'acc_type', 'acc_name', 'acc_no', 'bot_sms', 'auto_sms', 'bot_enet', 'auto_enet', 'bot_app', 'auto_app', 'public', 'bank_user', 'bank_pass', 'otp_number', 'device_id', 'bank_pin']
        Object.keys(this.bankAccuntForm).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && this.bankAccuntForm[keyItem] !== this.bankAccuntInfo[keyItem])
            changeMade[keyItem] = this.bankAccuntForm[keyItem]
        })
        try {
          if (Object.keys(changeMade).length === 0)
            return
          const { data: saveBankAccountResult } = await this.$http.put(`bankaccounts/${this.bankAccuntInfo.id}`, { bankaccount: changeMade })
          if (saveBankAccountResult.success) {
            this.$bvToast.toast(`บัญชี ${this.bankAccuntInfo.acc_name} ${this.bankAccuntInfo.acc_no}`, {
              variant: 'success',
              title: 'แก้ไขข้อมูลบัญชีสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            location.reload()
            this.mode = 'update'
            return
          }
          throw new Error(saveBankAccountResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'แก้ไขข้อมูลบัญชีผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } else {
        console.log(this.mode, this.bankAccuntForm, this.bankAccuntInfo)
        const changeMade = {}
        const required = {
          bank_code: 'ธนาคาร',
          acc_type: 'ประเภทบัญชี',
          acc_no: 'เลขที่บัญชี',
          acc_name: 'ชื่อบัญชี',
        }

        try {
          const { data: saveBankAccountResult } = await this.$http.post('bankaccounts', { bankaccount: this.bankAccuntForm })
          if (saveBankAccountResult.success) {
            this.bankAccuntForm = { ...saveBankAccountResult.bank_account }
            this.bankAccuntInfo = { ...saveBankAccountResult.bank_account }
            this.$bvToast.toast(` ${this.bankAccuntForm.acc_name} ${this.bankAccuntForm.acc_no}`, {
              variant: 'success',
              title: 'เพิ่มบัญชีสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            this.$router.replace({ name: 'manage-bankaccount-detail', params: { bankaccount: saveBankAccountResult.bank_account.id } })
            return
          }
          throw new Error(saveBankAccountResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'เพิ่มบัญชีผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      }
    },
    async fetchBankAccount(id) {
      const { data: _bankaccounts } = await this.$http.get(`bankaccounts/${id}?mode=v2`)
      this.bankAccuntInfo = { ..._bankaccounts.bankaccount }
      this.bankAccuntForm = { ..._bankaccounts.bankaccount }
      // .map(i => ({ ...i, title: `${i.bank} ${i.acc_no} ${i.acc_name}` }))
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'DEPOSIT-TMW', acc_name: 'ฝากทรูมันนี่',
      // })
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'WITHDRAW-TMW', acc_name: 'ถอนทรูมันนี่', acc_type: 'OUT', queue_name: 'TMW',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deposit-manual', acc_name: 'ฝากมือ',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'withdraw-manual', acc_name: 'ถอนมือ', acc_type: 'OUT', queue_name: 'M',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deduction', acc_name: 'ตัดเครดิต/ยึดเครดิต', acc_type: 'DEDUCTION',
      // })
      // this.bankaccounts
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
